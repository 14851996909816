import React, { useState } from 'react'
import "./navbar.css"
import { HashLink as Link } from 'react-router-hash-link';
import Logo from '../../assets/Images/logo.png';
import { HiOutlineMenuAlt4 } from 'react-icons/hi'
import { AiOutlineClose } from 'react-icons/ai'

let navOptions = [
  {id:1, name:"home", label:"Home"},
  {id:2, name:"about", label:"About Us"},
  {id:3, name:"ourvalues", label:"Our Values"},
  {id:4, name:"ourmission", label:"Our Mission"},
  {id:5, name:"ourfeatures", label:"Our Features"},
  {id:6, name:"contact-us", label:"Contact"},

]

const Navbar = () => {
  const [isActive , setIsActive] = useState("home")
    const [nav, setNav] = useState(false)
    const handleNav = () => setNav(!nav)
  
  return (
    <div className='navbarContainer'>
      <div className="navbarLeft">
        <Link smooth to="#home" >
      <img src={Logo} alt="" className="logo"/>
        </Link>
      </div>
      <div  name='home' className={nav ? 'navbar navbar-bg' : 'navbar'}>
        <ul className="nav-menu" style={{ listStyle: 'none' }}>
          {navOptions.map((option)=>(
              <li className='topbarList' key={option.id}><Link smooth to={`#${option.name}`} onClick={()=>setIsActive(option.name)}  className= {`ListCoursesTopbar ${isActive === option.name ? "activeClass" : ""}`}>{option.label}</Link></li>
          ))}
        </ul>
      </div>
      <div className="hamburger" onClick={handleNav}>
          {!nav ? (<HiOutlineMenuAlt4 className='icon' style={{ color: '#307BC4' }}/>) : (<AiOutlineClose style={{ color: '#307BC4' }} className='icon' />)}

      </div>
      {/* this class is mobile-menu */}
      <div className={nav ? 'mobile-menu active' : 'mobile-menu'}>
          <ul className="mobile-nav" style={{ listStyle: 'none' }}>
          <li ><Link smooth to="#home"  >Home</Link></li>
            <li ><Link smooth to="#about" >About Us</Link></li>
            <li ><Link smooth to="#services" >Services</Link></li>
            <li ><Link smooth to="#contact" >Contact</Link></li>
       
          </ul>
      </div>
    </div> 
 )
}

export default Navbar