import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function ContactUs() {
  return (
    <div className="ContactUs">
        <div className='contactUs-top'>
      <header className="ContactUs-header">
        <h1 style={{fontSize:'20px'}}>MidCure solutions</h1><br/>
        <div className="contact-info">
          <p className="contact-info-p">Opp ICL Courier Office,
            Saraswathi Nagar,
            Nizamabad, Telanagana</p>
          <p className="contact-info-p">+91 87900 143 96</p>
          <p className="contact-info-p">info@midcure.com</p>
        </div>
      </header>
      <nav className="ContactUs-nav">
        <ul>
          <Link smooth to="#home"><li>Home</li></Link>
          <Link smooth to="#about"><li>About Us</li></Link>
          <Link smooth to="#ourvalues"><li>Our Values</li></Link>
          <Link smooth to="#ourmission"><li>Our Mission</li></Link>
          <Link smooth to="#ourfeatures"><li>Our Features</li></Link>
        </ul>
      </nav>
      <section className="ContactUs-subscribe">
        <h2>Be Our Subscribers</h2>
        <p>To get the latest news about health from our experts</p>
        <div className='mail-div'>
        <input type="email" className='contactInnput' placeholder="example@email.com"/>
        <button>Submit</button>
        </div>
      </section>
        </div><br/>
    </div>
  );
}

export default ContactUs;
