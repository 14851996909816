import React, { useEffect } from 'react'
import Aos from "aos";
import "aos/dist/aos.css";
import { Card, CardContent, Grid } from '@mui/material';
// import { blue } from '@mui/material/colors';
import './components.css'

const values = [
  {
    title: 'Innovation',
    description:' Embracing cutting-edge technology to redefine healthcare services.',
    icon: '🤝' 
  },
  {
    title: 'Security',
    description:'Ensuring the highest standards of data privacy and confidentiality.',
    icon: '🌟' 
  },
  {
    title: 'Community Impact',
    description:'Collaborating with local partners for meaningful community engagement.',
    icon: '✅' 
  }
];

const ValueCard = ({ title, description, icon }) => {
  return (
    <div data-aos="zoom-in-up" >
    <Card sx={{ minWidth: 250, boxShadow: 5,borderRadius:7 ,minHeight:130 }}>
      <CardContent>
        <div className='cardHeader'>
          {icon} {title}
        </div>
        <div className='cardDescription'>
          {description}
        </div>
      </CardContent>
    </Card>
    </div>
  );
};

const OurValues = () => {
  useEffect(() => {
    Aos.init({duration: 2000})
  },[])
  return (
    <div style={{ flexGrow: 1 }} className='value-card'>
        <div className='typoHeaderDiv'>
            <div data-aos="fade-up" className='typographyClass'>
                Our Values
            </div>
      </div>
      <Grid container spacing={2}>
        {values.map((value, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ValueCard {...value}/>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default OurValues;
