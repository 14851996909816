import React, { useEffect } from 'react'
import Aos from "aos";
import "aos/dist/aos.css";
import f1 from '../../assets/Images/f1.png';
import f2 from '../../assets/Images/f2.png';
import f3 from '../../assets/Images/sudheer.jpg';

const AboutUs = () => {
    useEffect(() => {
        Aos.init({duration: 2000})
      },[])
  return (
    <>
       <h2 className="typographyClass" style={{marginTop:'0px'}}>About Us</h2> 
    <div className="about-us-container">
      <div className="about-us-content">
        <div className="about-us-text">
        <h2>Who We Are: Pioneering Healthcare Transformation</h2> <br/>
          <p className='serviceInfo' style={{textAlign:'left',color:'#274760',fontWeight:'500'}}>
          At MidCure, we are on a mission to revolutionize healthcare in Telangana. Our commitment to efficiency, security, and community engagement sets us apart. With a district-wise database, secure cloud infrastructure, and a focus on emergency response, MidCure is poised to transform how healthcare is managed and accessed in the state.
          </p>
          <br/><br/><br/><br/>
          {/* <div className="about-us-text"> */}
        <h2>Our Vision</h2>
          <p className='serviceInfo' style={{textAlign:'left',color:'#274760',fontWeight:'500'}}>
          To create a healthcare ecosystem where every resident of Telangana has access to instant, secure, and personalized health information, contributing to quicker and more effective emergency responses and improved overall well-being.          </p>
        </div>
        {/* </div> */}

        <div className="about-us-images">
          <div className="image-container doctor-image">
            <div className='founders'>
            <img src={f1} alt=""/>
            <p className='founderName'>CEO & CO-Founder  Mohammed Noor </p>
            </div>
          </div>
          <div className="image-container patient-image">
          <div className='founders'>
            <img src={f3} alt=""/>
            <p className='founderName'>CTO & CO-Founder Sudheer Kumar </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default AboutUs;
