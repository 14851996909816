import React, { useEffect } from 'react'
import Aos from "aos";
import "aos/dist/aos.css";
import { Card, CardContent, Grid } from '@mui/material';
// import { blue } from '@mui/material/colors';
import './components.css'

const values = [
  {
    description:' Streamlining healthcare data management through innovative technology.',
  },
  {
    description:'Enhancing emergency response times for better patient outcomes.',
  },
  {
    description:'Actively engaging with local communities to raise health awareness and promote early disease detection.',
  }
];
const ValueCard = ({ description}) => {
    return (
      <div data-aos="zoom-in-up">
        <Card sx={{ minWidth: 250, boxShadow: 5,borderRadius:7 ,minHeight:130}}>
          <CardContent>
            <div className='cardDescription'>
              {description}
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };
const OurMission = () => {
  useEffect(() => {
    Aos.init({duration: 2000})
  },[])
  return (
    <div style={{ flexGrow: 1 }} className='value-card'>
        <div className='typoHeaderDiv'>
            <div data-aos="fade-up"  className='typographyClass'>
                Our Mission
            </div>
        </div>
        <Grid container spacing={2}>
            {values.map((value, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
                <ValueCard {...value} />
            </Grid>
            ))}
        </Grid>
    </div>
  )
}

export default OurMission