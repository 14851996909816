import React, { useState, useEffect } from 'react';
import { Paper, Typography, Box, ButtonBase } from '@mui/material';
import image3 from '../../assets/Images/image5.png';
import image1 from '../../assets/Images/image2.png';
import image2 from '../../assets/Images/bgimg3.png';

const slidesData = [
    {
      backgroundImage: `url(${image1})`,
      content: 'Transforming Emergency Response MidCure empowers emergency response teams with instant access to critical health data, potentially saving lives in crucial moments. Our district-wise database ensures efficient data management, enabling faster and more informed decisions during emergencies.',
    },
    {
      backgroundImage: `url(${image2})`,
      content: 'Secure Cloud Infrastructure Your health data is our priority. MidCure adheres to the highest standards of data security, following HIPAA compliance and SSN encryption. Rest easy knowing that your information is safeguarded in separate AWS buckets for each district, ensuring privacy and confidentiality.',
    },
    {
      backgroundImage: `url(${image3})`,
      content: 'Community-Centric Healthcare MidCure goes beyond data management; we actively engage with local communities through health camps and partnerships with colleges. Our goal is to enhance public health awareness, promote early disease detection, and provide seamless healthcare services to residents of Telangana.',
    },
  ];
const Section1 = () => {
    const [activeSlide, setActiveSlide] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setActiveSlide((prevActiveSlide) => (prevActiveSlide + 1) % slidesData.length);
      }, 4000);
      return () => clearInterval(interval);
    }, []);
  return (
    <div className='section1Div'>   
    <Box position="relative">
    {slidesData.map((slide, index) => (
      <Box
        key={index}
        component={Paper}
        style={{
          backgroundImage: slide.backgroundImage,
          display: activeSlide === index ? 'block' : 'none',
          minHeight: '90vh', 
          backgroundSize: 'cover', 
          backgroundPosition: 'center',
        }}
      >
        <h1 className='page1-h1'>Welcome to Midcure</h1>
        <div className='page1-text'
        //  style={{
        //     width:"50%",
        //     paddingLeft:'60px',
        //     // paddingTop:'100px',
        //     paddingBottom:"20px",
        //     fontSize:"30px",
        //     color:"#274760",
        //     fontWeight:"400",
        //     textAlign:'justify'
        //  }}
        >{slide.content}</div>
      </Box>
    ))}
    <Box position="absolute" bottom={0} right={0}>
      {slidesData.map((_, index) => (
        <ButtonBase
          key={index}
          onClick={() => setActiveSlide(index)}
          style={{
            margin: 4,
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: activeSlide === index ? 'blue' : 'grey',
          }}
        />
      ))}
    </Box>
  </Box>
  </div>
  )
}

export default Section1