import React, { useEffect } from 'react'
import Aos from "aos";
import "aos/dist/aos.css";

let teamList = [
    {id:1, name:"District-Wise Database", personinfo:"Efficiently manage and organize healthcare information with separate AWS buckets for each district, ensuring focused data management and accessibility"},
    {id:2, name:"Secure Cloud Infrastructure", personinfo:"MidCure follows HIPAA compliance and SSN encryption patterns, prioritizing data security and safeguarding your health information."},
    {id:3, name:"Emergency Focus", personinfo:"Instant access to critical health data for emergency response teams, potentially saving lives through informed decision-making."},
    {id:4, name:"Community Engagement", personinfo:"Partnering with local colleges for health camps, offering free checkups, and linking digital health records to Aadhar cards for enhanced community engagement."},
    {id:5, name:"Automated Calls", personinfo:"Facilitating medication reminders and purchase alerts through automated calls, promoting adherence to prescribed treatments."},
    {id:6, name:"Early Disease Detection", personinfo:" Empowering individuals with a Health Security Number (HSN) card for instant access to comprehensive health reports, particularly focusing on early detection of diseases like diabetes, breast cancer, and cervical cancer."},
    ]

const OurFeatures = () => {
  useEffect(() => {
    Aos.init({duration: 2000})
  },[])
  return (
    <div className='backgroundImg'>
    <div className='aboutUsContainer'>
      <div className="aboutUsContent">
       <h2 data-aos="fade-down" className="typographyClass">Our Features</h2> 
       <h2 data-aos="fade-up" className="typographyClass">Empowering Health and Wellness</h2> 
            <div className='teamInfoContainerBottom'>
              {teamList?.map((teaminfo) => (
                  <div className="bottomContainer" data-aos="flip-left" key={teaminfo._id}>
                    {/* <Avatar src={teaminfo?.source} variant="square" sx={{ width: '100%', height: '100%' }}/> */}
                    <div className='serviceName'>{teaminfo.name}</div>
                    <div className='serviceInfo'>{teaminfo.personinfo}</div>
                  </div>
              ))}
          </div>
      </div>
    </div>  
    </div>
  )
}

export default OurFeatures