import React, { useEffect } from 'react'
import "./home.css"
import Aos from "aos";
import "aos/dist/aos.css";
import Navbar from '../Layout/Navbar';
import Section1 from '../Components/Section1';
import OurValues from '../Components/OurValues';
import OurMission from '../Components/OurMission';
import OurFeatures from '../Components/OurFeatures';
import AboutUs from '../Components/AboutUs';
import ContactUs from '../Components/ContactUs';
// import AboutUs from '../../components/aboutUs/AboutUs';
// import Services from '../../components/services/Services';
// import Contact from '../../components/contact/Contact';

const Home = () => {
    useEffect(() => {
    Aos.init({duration: 2000})
  },[])

  return (
    <div className='homepage'>
                <Navbar/>
        <div className='homeContainer'>
            <section id='home' className='home'>
                <Section1/>
            </section>
        </div>
        <div className='about-container'>
        <section id='about' className='about'>
            <AboutUs/>    
        </section> 
        </div>
        <div className='ourvalues'>
        <section id='ourvalues' className='ourvalues'>
            <OurValues/>    
        </section> 
        </div>
        <div className='ourvalues'>
        <section id='ourmission' className='ourmission'>
            <OurMission/>    
        </section> 
        </div>
        <div className='ourvalues'>
        <section id='ourfeatures' className='ourfeatures'>
            <OurFeatures/>    
        </section> 
        </div>
        <div className='containner'>
        <section id='contact-us' >
            <ContactUs/>    
        </section> 
        </div>
  </div>
)
}

export default Home